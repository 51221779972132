<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}
</style>
<template>
  <div>
    <CRow>
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      ></loading>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Cambiar un beneficiario</strong>
          </CCardHeader>
          <CCardBody>
            <div class="form-group col-md-7">
              <label for="">Diario</label>
              <select
                class="form-control"
                name=""
                id=""
                @change="changeCategory($event)"
              >
                <option
                  v-for="item in arrCategory"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-7">
              <label for="">Paquetes</label>
              <select
                class="form-control"
                name=""
                id=""
                @change="changeSubCategory($event)"
              >
                <option
                  v-for="item in arrSubCategory"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-7">
              <label for="">Seleccione una suscripción</label>
              <select class="form-control" @change="changeSuscription($event)">
                <option value="">--Seleccionar--</option>
                <option
                  v-for="(item, index) in suscripciones"
                  :value="index + 1"
                  :key="item.id"
                >
                  {{ item.product }} - {{ item.id }}
                </option>
              </select>
            </div>

            
            <!-- titulo de datos del beneficicario -->
            <div class="form-group col-md-7">
              <CCardHeader style="padding: 0 !important">
                <strong> Datos del beneficiario</strong>
              </CCardHeader>
              <p>
                Los datos a ingresar aquí son datos únicamente del beneficiario.
              </p>
              <hr />
            </div>

            <!-- //TODO: Tipo documento Y N° Documento -->
            <div class="form-group col-md-7">
              <div class="row">
                <div class="col-md-6">
                  <label>Tipo documento</label>
                  <p
                    v-if="isTipoDocumentoBeneficiario"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo tipo de documento está vacio
                  </p>
                  <select
                    disabled
                    id="selectTipoDocumentoBeneficiario"
                    v-model="tipoDocumentoBeneficiario"
                    class="form-control"
                    style="color: black !important"
                    @change="changeTipoDocumentoBeneficiario($event)"
                  >
                    <option value="">--Seleccionar--</option>
                    <option value="DNI">DNI</option>
                    <option value="RUC">RUC</option>
                    <option value="Carnet de extranjeria">
                      Carnet de extranjeria
                    </option>
                    <option value="Pasaporte">Pasaporte</option>
                    <option value="CDI">CDI</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label>N° Documento </label>
                  <p
                    v-if="isNumeroDocumentoBeneficiario"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*) El campo Numero documento esta vacio
                  </p>
                  <input
                    v-model="numeroDocumentoBeneficiario"
                    type="text"
                    class="form-control"
                    id="inputNumeroDocumentoBeneficiario"
                    @keypress="validateNumberBeneficiario($event)"
                    @keyup="validateLengthBeneficiario($event)"
                    disabled
                  />
                </div>
              </div>
            </div>

            <!--Nombres -->
            <div class="form-group col-md-7">
              <label>Nombres</label>
              <p
                v-if="isNombresBeneficiario"
                style="
                  color: red;
                  font-size: 0.8em;
                  margin: 0 0 5px 0;
                  padding: 0;
                "
              >
                (*)El campo está vacio
              </p>
              <input
                id="inputNombresBeneficiario"
                type="text"
                class="form-control"
                v-model="nombresBeneficiario"
                @keypress="validateOnlyLetter($event)"
                maxlength="50"
                disabled
              />
            </div>
            <!-- Apellido-->
            <div class="form-group col-md-7">
              <div class="row">
                <div class="col-md-6">
                  <label>Apellido Paterno</label>
                  <p
                    v-if="isApellidoPaBeneficiario"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo está vacio
                  </p>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    id="inputApellidoPaBeneficiario"
                    v-model="apellidoPaBeneficiario"
                    @keypress="validateOnlyLetter($event)"
                    maxlength="30"
                  />
                </div>
                <div class="col-md-6">
                  <label>Apellido Materno</label>
                  <p
                    v-if="isApellidoMaBeneficiario"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo está vacio
                  </p>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    id="inputApellidoMaBeneficiario"
                    v-model="apellidoMaBeneficiario"
                    @keypress="validateOnlyLetter($event)"
                    maxlength="25"
                  />
                </div>
              </div>
            </div>
            <!--Correo -->
            <div class="form-group col-md-7">
              <label for="">Correo</label>
              <p
                v-if="isCorreoBeneficiario"
                style="
                  color: red;
                  font-size: 0.8em;
                  margin: 0 0 5px 0;
                  padding: 0;
                "
              >
                (*)Falta ingresar tu correo del beneficiario
              </p>
              <input
                disabled
                id="inputCorreoBeneficiario"
                placeholder="ejemplo@gmail.com..."
                type="email"
                v-model="correoBeneficiario"
                class="form-control"
              />
            </div>
            <!-- titulo de datos del beneficicario -->
            <div class="form-group col-md-7">
              <hr />
            </div>
            <!-- Registrar freshdesk -->
            <div class="form-group col-md-7">
              <button
                id="boton"
                disabled
                type="button"
                class="btn-comercio btn__ingresar"
                @click="registrarBeneficiario()"
              >
                Enviar
              </button>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <img class="avatar__alicia" src="../../assets/img/nueva_alicia_pages_all.png" />
    <img class="avatar__alicia__mobile" src="../../assets/img/nueva_alicia_pages_all.png" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Cards",
  data: function () {
    return {
      suscripciones: [],
      nameSuscription: "Aún no ha seleccionado una suscripción",
      suscriptionSelected: {},
      checked: false,
      categoryId: "",
      subCategoryId: "",
      selectedCategoria: "0",
      tipoSuriptor: "",
      dateIncident: "",
      establecimiento: "",
      promocion: "",
      correo: "",
      observacion: "",
      idSuscripcion: "",
      nombrePackete: "",
      nombreSuscriptor: "",

      tipoDocumentoBeneficiario: "",
      numeroDocumentoBeneficiario: "",
      nombresBeneficiario: "",
      apellidoPaBeneficiario: "",
      apellidoMaBeneficiario: "",
      correoBeneficiario: "",

      show: true,
      isCollapsed: true,
      isTipoSuriptor: false,
      isEstablecimiento: false,
      isPromocion: false,
      isCorreo: false,
      isObservacion: false,

      isTipoDocumentoBeneficiario: false,
      isNumeroDocumentoBeneficiario: false,
      isNombresBeneficiario: false,
      isApellidoPaBeneficiario: false,
      isApellidoMaBeneficiario: false,
      isCorreoBeneficiario: false,

      isLoading: false,
      fullPage: true,
      arrCategory: [
        { id: "", name: "--Seleccionar--" },
        { id: "1", name: "El Comercio" },
        { id: "2", name: "Gestión" },
      ],
      arrTipoSuscrip: [
        { id: "", name: "--Seleccionar--" },
        { id: "1", name: "Suscriptor" },
        { id: "2", name: "Beneficiario" },
      ],
      arrSubCategory: [{ id: "", name: "--Seleccionar--" }],
    };
  },
  components: {
    Loading,
  },
  methods: {
    changeCategory(event) {
      let vm = this;
      let id = event.target.value;
      vm.categoryId = id;
      vm.isLoading = true;

      if (id == "") {
        vm.arrSubCategory = [{ id: "", name: "--Seleccionar--" }];
      } else if (id == "1") {
        vm.arrSubCategory = [
          { id: "", name: "--Seleccionar--" },
          { id: "1", name: "El Comercio" },
          { id: "2", name: "El Comercio Impreso + Digital" },
          { id: "3", name: "El Comercio Digital" },
        ];
      } else if (id == "2") {
        vm.arrSubCategory = [
          { id: "", name: "--Seleccionar--" },
          { id: "4", name: "Gestión" },
          { id: "5", name: "Gestión Impreso + Digital" },
          { id: "6", name: "Gestión Digital" },
        ];
      }
      vm.suscripciones = [];
      vm.suscriptionSelected = {};
      vm.isLoading = false;
      vm.nameSuscription = "Aún no ha seleccionado una suscripción";
      document.getElementById("tipo_suscriptor").disabled = true;
      vm.isLoading = false;
    },
    changeSubCategory(event) {
      let vm = this;
      let id = event.target.value;
      vm.subCategoryId = id;
      vm.isLoading = true;

      vm.suscripciones = [];
      vm.suscriptionSelected = {};
      vm.nameSuscription = "Aún no ha seleccionado una suscripción";

      if (id == "") {
        vm.isLoading = false;
      } else {
        let data = {
          nro_documento: localStorage.getItem("numeroDocumento"),
          tipo_documento: localStorage.getItem("tipoDocumento"),
          categoria: vm.categoryId,
          subcategoria: id,
          canal: "7",
        };
        console.log("otra data", data);
        axios
          .post(`${process.env.VUE_APP_ROOT_API}/listSuscripciones`, data)
          .then(function (res) {
            vm.isLoading = false;
            if (res.data.data.code == "1") {
              vm.suscripciones = res.data.data.landing;
              console.log("suscripciones", vm.suscripciones);
            } else {
              vm.isLoading = false;
              vm.$fire({
                title: "Información",
                text: "No tiene suscripciones en este paquete.",
                type: "info",
                timer: 4000,
              }).then((r) => {});
            }
          })
          .catch((error) => {
            vm.isLoading = false;
            vm.$fire({
              title: "Error",
              text: "No tiene suscripciones en este paquete.",
              type: "error",
              timer: 4000,
            }).then((r) => {});
          });
      }
    },
    changeSuscription(event) {
      let vm = this;
      vm.idSuscripcion = event.target.value;
      vm.isLoading = true;
      vm.suscriptionSelected = {};
      vm.nameSuscription = "Aún no ha seleccionado una suscripción";

      if (vm.idSuscripcion == "") {
        vm.isLoading = false;
      } else {
        let data = {
          position_id: vm.idSuscripcion,
          document_number: localStorage.getItem("numeroDocumento"),
          document_type: localStorage.getItem("tipoDocumento"),
          platformOrigin: "Landing",
        };

        axios
          .post(`${process.env.VUE_APP_ROOT_API}/validar-deuda`, data)
          .then(function (res) {
            vm.nombreSuscriptor = res.data.data.name;
            console.log(res);
            vm.isLoading = false;
            vm.suscriptionSelected = res.data.data;

            if (vm.suscriptionSelected.code == 1) {
              vm.isLoading = false;
              vm.nombrePackete = vm.suscriptionSelected.productos;
              vm.$fire({
                title: "Información",
                text: `${vm.suscriptionSelected.name}, registra a tu nuevo beneficiario `,
                type: "info",
                timer: 2000,
              }).then((r) => {});

              document.getElementById(
                "inputNombresBeneficiario"
              ).disabled = false;
              document.getElementById(
                "inputNumeroDocumentoBeneficiario"
              ).disabled = false;
              document.getElementById(
                "inputApellidoPaBeneficiario"
              ).disabled = false;
              document.getElementById(
                "inputApellidoMaBeneficiario"
              ).disabled = false;
              document.getElementById(
                "inputCorreoBeneficiario"
              ).disabled = false;
              document.getElementById(
                "selectTipoDocumentoBeneficiario"
              ).disabled = false;
              document.getElementById("boton").disabled = false;
              vm.nameSuscription = `La suscripción que ha seleccionado es: ${vm.suscriptionSelected["productos"]}`;
            } else {
              vm.isLoading = false;
              vm.$fire({
                title: "Información",
                text: `${vm.suscriptionSelected.name}, actualmente tienes una deuda de S/. ${vm.suscriptionSelected.deuda}, por ello no puedes registrar a un beneficiario`,
                type: "info",
                timer: 2000,
              }).then((r) => {});
              document.getElementById(
                "inputNombresBeneficiario"
              ).disabled = true;
              document.getElementById(
                "inputApellidoPaBeneficiario"
              ).disabled = true;
              document.getElementById(
                "inputApellidoMaBeneficiario"
              ).disabled = true;
              document.getElementById(
                "inputNumeroDocumentoBeneficiario"
              ).disabled = true;
              document.getElementById(
                "inputCorreoBeneficiario"
              ).disabled = true;
              document.getElementById(
                "selectTipoDocumentoBeneficiario"
              ).disabled = true;
              document.getElementById("boton").disabled = true;
            }
          });
      }
    },
    /* TODO:validar numero beneficicario */
    validateNumberBeneficiario(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    /* TODO:validar tamaño del numero beneficiario */
    validateLengthBeneficiario(event) {
      let vm = this;
      let val = event.target.value;
      let len = 20;
      if (vm.tipoDocumentoBeneficiario == "DNI") len = 8;
      else if (vm.tipoDocumentoBeneficiario == "RUC") len = 11;
      if (val.length > len) {
        vm.numeroDocumentoBeneficiario = event.target.value.substring(0, len);
      }
    },
    /* TODO:validar tipo documento Beneficiario */
    changeTipoDocumentoBeneficiario(event) {
      let vm = this;
      let id = event.target.value;
      vm.tipoDocumentoBeneficiario = id;
      vm.numeroDocumentoBeneficiario = "";
    },
    validateOnlyLetter(event) {
      let val = event.key;
      let myRex = new RegExp("^[a-zA-z ]$");
      if (!myRex.test(val)) event.preventDefault();
    },

    async registrarBeneficiario() {
      let vm = this;

      vm.isTipoDocumentoBeneficiario = false;
      vm.isNumeroDocumentoBeneficiario = false;
      vm.isNombresBeneficiario = false;
      vm.isApellidoPaBeneficiario = false;
      vm.isApellidoMaBeneficiario = false;
      vm.isCorreoBeneficiario = false;

      if (vm.tipoDocumentoBeneficiario == "") {
        vm.isTipoDocumentoBeneficiario = true;
      }
      if (vm.numeroDocumentoBeneficiario == "") {
        vm.isNumeroDocumentoBeneficiario = true;
      }

      if (vm.nombresBeneficiario == "") {
        vm.isNombresBeneficiario = true;
      }
      if (vm.apellidoPaBeneficiario == "") {
        vm.isApellidoPaBeneficiario = true;
      }
      if (vm.apellidoMaBeneficiario == "") {
        vm.isApellidoMaBeneficiario = true;
      }
      if (vm.correoBeneficiario == "") {
        vm.isCorreoBeneficiario = true;
      }

      //validaciones de campos

      if (
        vm.isTipoDocumentoBeneficiario == false &&
        vm.isNumeroDocumentoBeneficiario == false &&
        vm.isNombresBeneficiario == false &&
        vm.isApellidoPaBeneficiario == false &&
        vm.isApellidoMaBeneficiario == false &&
        vm.isCorreoBeneficiario == false
      ) {
        if (
          !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
            vm.correoBeneficiario
          )
        ) {
          vm.$fire({
            title: "Seguridad",
            text: "La dirección de correo es incorrecta.",
            type: "info",
            timer: 4000,
          }).then((r) => {
            console.log("dismin", r);
          });
        } else {
          //registrar en freshdesk
          let vm = this;

          let data = {
            document_number: localStorage.getItem("numeroDocumento"),
            document_type: localStorage.getItem("tipoDocumento"),
            positionId: vm.idSuscripcion,
            email: vm.correoBeneficiario,
            firstname: vm.nombresBeneficiario,
            lastname: vm.apellidoPaBeneficiario + " " + vm.apellidoMaBeneficiario,
            documentType: vm.tipoDocumentoBeneficiario,
            documentNumber: vm.numeroDocumentoBeneficiario,
            platformOrigin: "Landing",
          };
          let response = await axios
            .post(
              `${process.env.VUE_APP_ROOT_API}/actualizardatos-beneficiario`,
              data
            )
            .then(function (res) {
                console.log(res);
              vm.isLoading = false;
              return res.data.data;
            });
          if (response.code == 1) {
            vm.$fire({
              title: `Solicitud realizada con éxito`,
              text: `Tu invitado recibirá un instructivo vía correo para proceder con la actualización.
              \n\nRecuerda que tiene 24 horas para aceptar la invitación, caso contrario será necesario repetir la solicitud. `,
              icon: "success",
              timer: 5000,
            }).then((r) => {});
          } else if (response.code == 2) {
            vm.$fire({
              title: `Tienes una solicitud pendiente de cambio de beneficiario, vuelve a intentarlo en 24 horas`,
              type: "info",
              timer: 3000,
            }).then((r) => {});
           
          } else if (response.code == 4) {
            vm.$fire({
              title: `El número de documento ingresado para registrar al beneficiario  coincide con el número de documento del suscriptor.`,
              type: "info",
              timer: 3000,
            }).then((r) => {});
           
          }
          //fin de registro en freshdesk
        }
      }
    },
  },
  filters: {
    currency(value) {
      return `S/ ${value}`;
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();
  },
};
</script>
